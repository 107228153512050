/* eslint-disable no-process-env */
import { AuthProviderType } from '@newfront-insurance/next-auth';
import type { AuthProviderContext, AuthProviderProps } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import type { ReactNode } from 'react';

interface Props {
  authProvider: Provider<AuthProviderContext, AuthProviderProps>;
  children: ReactNode;
  useConditionalAuthProvider: boolean;
}

export function ConditionalAuthProvider({
  authProvider: AuthProvider,
  useConditionalAuthProvider,
  children,
}: Props): JSX.Element {
  if (useConditionalAuthProvider) {
    return <AuthProvider>{children}</AuthProvider>;
  }

  return <AuthProvider providerType={AuthProviderType.KEYCLOAK_JS}>{children}</AuthProvider>;
}

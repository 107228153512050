import { useLDClient } from 'launchdarkly-react-client-sdk';

/**
 * Load the value of a single feature flag, providing a fallback value. The key should match the
 * name of the flag in LaunchDarkly exactly if `useCamelCaseFlagKeys` is set to false, otherwise it
 * should be the camelcase version of the key.
 */
export function useFeatureFlag<T>(key: string, fallbackValue: T, accountUuid?: string): T {
  const client = useLDClient();

  if (!client) {
    return false as T;
  }

  const flagValue = client.variation(key, fallbackValue);

  // If flag contains accountUuids, evaluate feature flag by accountUuid
  if (flagValue.accountUuids) {
    if (!accountUuid) {
      return fallbackValue;
    }
    return flagValue.accountUuids.includes(accountUuid) ? (true as T) : fallbackValue;
  }

  // Else simply return the flagValue
  return flagValue;
}

import { useFeatureFlag } from '@newfront-insurance/admin-ui';
import { useNewfrontLocalStorage } from '@newfront-insurance/react-hooks';
import type { ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useMemo, useEffect } from 'react';
import Snowfall from 'react-snowfall';

interface SnowfallContextType {
  isSnowfallEnabled: boolean;
  toggleSnowfall: () => void;
}

const SnowfallContext = createContext<SnowfallContextType | undefined>(undefined);

export function SnowfallProvider({ children }: { children: ReactNode }) {
  const isSnowfallFeatureFlagEnabled = useFeatureFlag('snowfall', false);
  const [isSnowfallEnabled, setIsSnowfallEnabled] = useNewfrontLocalStorage<boolean>('isSnowing', false);

  useEffect(() => {
    /**
     * Used to sync the snowfall state across tabs
     * ie. if we turn off/on snowfall in one tab, it should be reflected in all tabs
     */
    const channel = new BroadcastChannel('snowfall-channel');

    channel.onmessage = (event) => {
      if (event.data.type === 'TOGGLE_SNOWFALL') {
        setIsSnowfallEnabled(event.data.isEnabled);
      }
    };

    return () => channel.close();
  }, [setIsSnowfallEnabled]);

  const toggleSnowfall = useCallback(() => {
    const newValue = !isSnowfallEnabled;
    setIsSnowfallEnabled(newValue);

    // Broadcast the change to other tabs
    const channel = new BroadcastChannel('snowfall-channel');
    channel.postMessage({ type: 'TOGGLE_SNOWFALL', isEnabled: newValue });
  }, [isSnowfallEnabled, setIsSnowfallEnabled]);

  const value = useMemo(
    () => ({
      isSnowfallEnabled: isSnowfallEnabled ?? false,
      toggleSnowfall,
    }),
    [isSnowfallEnabled, toggleSnowfall],
  );

  return (
    <SnowfallContext.Provider value={value}>
      {children}
      {isSnowfallFeatureFlagEnabled && isSnowfallEnabled && <Snowfall />}
    </SnowfallContext.Provider>
  );
}

export function useSnowfall() {
  const context = useContext(SnowfallContext);
  if (context === undefined) {
    throw new Error('useSnowfall must be used within a SnowfallProvider');
  }
  return context;
}

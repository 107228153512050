import { ToolbarButton } from './components/toolbar-button';

interface Props {
  snowfallProvider: {
    isSnowfallEnabled: boolean;
    toggleSnowfall: () => void;
  };
}

export function SnowfallToggle({ snowfallProvider: { isSnowfallEnabled, toggleSnowfall } }: Props): JSX.Element {
  return (
    <ToolbarButton
      tabIndex={0}
      title={isSnowfallEnabled ? 'Stop snowing' : 'Start snowing'}
      onClick={(e) => {
        toggleSnowfall();
        // Unfocus the button after clicking it
        e.currentTarget.blur();
      }}
    >
      <SnowflakeIcon className={isSnowfallEnabled ? 'opacity-100' : 'opacity-50'} />
    </ToolbarButton>
  );
}

function SnowflakeIcon({ className }: { className?: string }) {
  return (
    <svg fill="currentColor" viewBox="0 0 16 16" height="18" width="18" className={className}>
      <path
        d="M8 16a.5.5 0 01-.5-.5v-1.293l-.646.647a.5.5 0 01-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496
      1.85a.5.5 0 11-.966-.26l.237-.882-1.12.646a.5.5 0 01-.5-.866l1.12-.646-.884-.237a.5.5 0 11.26-.966l1.848.495L7
      8 3.6 6.037l-1.85.495a.5.5 0 01-.258-.966l.883-.237-1.12-.646a.5.5 0 11.5-.866l1.12.646-.237-.883a.5.5 0
      11.966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 11.707-.708l.646.647V.5a.5.5 0 111 0v1.293l.647-.647a.5.5
      0 11.707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 11.966.26l-.236.882 1.12-.646a.5.5 0 01.5.866l-1.12.646.883.237a.5.5
      0 11-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 01.259.966l-.883.237 1.12.646a.5.5 0 01-.5.866l-1.12-.646.236.883a.5.5
      0 11-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 01-.707.708l-.647-.647V15.5a.5.5 0 01-.5.5z"
      />
    </svg>
  );
}
